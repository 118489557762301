export const motiefKleur = (soort, theme) => {
  let returnValue = null 
  switch (soort) {
    case 'werk':
      returnValue = theme.palette.motief.one
      break
    case 'school':
      returnValue = theme.palette.motief.two
      break
    case 'boodschappen':
      returnValue = theme.palette.motief.three
      break
    case 'vrijetijd':
      returnValue = theme.palette.motief.four
      break
    default:
      returnValue = theme.palette.motief.five
      break
  }
  return returnValue
}