// const strapiUrl = 'http://18.158.233.74:1337'
// const strapiUrl = 'http://localhost:1337'
// export const strapiUrl = 'http://18.193.22.222:1337'
export const strapiUrl = 'https://cms.savewithcarsharing.be:'



const axios = require('axios')

export const getFormulas = () => axios.get(`${strapiUrl}/formules?actief=true&_limit=-1&_sort=naam:ASC`)
export const getMotiefs = () => axios.get(`${strapiUrl}/motiefs`)
export const getAanbieders = () => axios.get(`${strapiUrl}/aanbieders`)
export const getAbos = () => axios.get(`${strapiUrl}/abonnements?actief=true`)
export const getOwnerCars = () => axios.get(`${strapiUrl}/eigenwagens`)
export const getAutos = () => axios.get(`${strapiUrl}/autos`)
export const getAlgoritme = () => axios.get(`${strapiUrl}/algoritme`)
export const getLabels = () => axios.get(`${strapiUrl}/labels?_limit=-1`)
export const getAfschrijving = () => axios.get(`${strapiUrl}/afschrijving`)
export const getBrandstofs = () => axios.get(`${strapiUrl}/brandstofs`)
export const getVersie = () => axios.get(`${strapiUrl}/versie`)
export const getRegios = () => axios.get(`${strapiUrl}/regios`)