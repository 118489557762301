// uitleg: zie algoritme.md

// A
export const calcMotiefsForAbo = (motiefs, abo, autos, brandstofs) => {
  // console.log('abo', abo.naam)
  let jaarPrijsMotiefs = 0
  let jaarKmMotiefs = 0
  let prepaid = null
  let motiefsWithJaarPrijs = motiefs.map((motief) => {
    const { formula, foundVolgnr, otherAuto } = findFormulaAndAutoForMotiefAndAbo(motief, abo)
    // prepaid is defined on formula-level, should be on abo-level instead
    if (formula.prepaid != null) {
      prepaid = formula.prepaid
    }
    // console.log('motief', motief)
    // console.log('formule', formula.naam)
    const auto = autos.find(({volgnr}) => volgnr === foundVolgnr)
    const kmPrijs = calcKmPrijsForMotiefAndFormula(formula, motief)
    // console.log('kmPrijs', kmPrijs);
    let tijdPrijs = 0 
    if (formula.naam === 'Green Mobility-pakket-1') {
      tijdPrijs = calcTijdPrijsForMotiefAndFormulaForGreenMobility(formula, motief)
      // console.log('tijdPrijs', tijdPrijs);
    } else {
      tijdPrijs = calcTijdPrijsForMotiefAndFormula(formula, motief)
    }
    const brandstofPrijs = calcBrandstofForMotiefAndFormula(formula, auto, motief, brandstofs)
    // console.log('brandstofPrijs', brandstofPrijs);
    const startPrijs = formula.startritprijs == null ? 0 : formula.startritprijs
    // console.log('startPrijs', startPrijs);
    const stilstandPrijs = calcStilstandprijs(formula, motief)    // uitgedrukt als korting!
    // console.log('stilstandPrijs', stilstandPrijs);
    const extraKmsPerPeriodePrijs = calcKmLimitsPriceForMotiefAndFormula(formula, motief)
    // console.log('extraKmsPerPeriodePrijs', extraKmsPerPeriodePrijs);
    let jaarPrijs = multiplyPriceWithFrequency(kmPrijs + tijdPrijs + brandstofPrijs + startPrijs - stilstandPrijs + extraKmsPerPeriodePrijs, motief)

    if (prepaid != null) {
      jaarPrijs = jaarPrijs * ((100-prepaid.korting) / 100)
    }
    // console.log('jaarPrijs', jaarPrijs);

    jaarPrijsMotiefs += jaarPrijs
    const jaarKm = motief.afstand  * motief.frequentie * (motief.frequentietype === "week" ? 52 : (motief.frequentietype === "maand" ? 12 : 1))
    jaarKmMotiefs += jaarKm
    return ({ ...motief, jaarPrijs, jaarKm, auto, otherAuto })
  })
  // console.log('jaarPrijsMotiefs', jaarPrijsMotiefs);
  
  // console.log('abo', abo.naam)
  const limietTijdJaarPrijs = calcTimeLimitsPriceForMotiefsAndAbo(motiefs, abo)
  // console.log('limietTijdJaarPrijs', limietTijdJaarPrijs)
  const limietKmJaarPrijs = calcKmLimitsPriceForMotiefsAndAbo(motiefs, abo)
  // console.log('limietKmJaarPrijs', limietKmJaarPrijs)
  const aboJaarPrijs = calcAboPriceForAYear(abo, prepaid)
  // console.log('aboJaarPrijs', aboJaarPrijs);

  let aboJaarPrijsResterend = aboJaarPrijs
  // als krediet: aboJaarPrijs (of prepaid) verminderen met verbruikte bedrag
  if (abo.isKrediet) {
    aboJaarPrijsResterend = aboJaarPrijs - jaarPrijsMotiefs
    if (aboJaarPrijsResterend < 0) {
      aboJaarPrijsResterend = 0
    }
  }
  // console.log('aboJaarPrijsResterend', aboJaarPrijsResterend);
  // console.log('-------------------------');
  
  return {
    jaarPrijsMotiefs: Math.round(jaarPrijsMotiefs + limietTijdJaarPrijs + limietKmJaarPrijs + aboJaarPrijsResterend),
    jaarPrijsMotiefsZonderAbo: Math.round(jaarPrijsMotiefs + limietTijdJaarPrijs + limietKmJaarPrijs),
    motiefs: motiefsWithJaarPrijs.map((motief) => ({ 
      ...motief,
      jaarPrijs: 
        motief.jaarPrijs
        + (aboJaarPrijsResterend === 0 ? 0 : Math.round(aboJaarPrijsResterend*(motief.jaarKm/jaarKmMotiefs)))        // aboJaarPrijs verdelen volgens gewicht
        + (limietTijdJaarPrijs === 0 ? 0 : Math.round(limietTijdJaarPrijs*(motief.jaarKm/jaarKmMotiefs)))  // limietTijdJaarPrijs verdelen volgens gewicht
        + (limietKmJaarPrijs === 0 ? 0 : Math.round(limietKmJaarPrijs*(motief.jaarKm/jaarKmMotiefs))),  // limietKmJaarPrijs verdelen volgens gewicht
      jaarPrijsZonderAbo: 
        motief.jaarPrijs
        + (limietTijdJaarPrijs === 0 ? 0 : Math.round(limietTijdJaarPrijs*(motief.jaarKm/jaarKmMotiefs)))  // limietTijdJaarPrijs verdelen volgens gewicht
        + (limietKmJaarPrijs === 0 ? 0 : Math.round(limietKmJaarPrijs*(motief.jaarKm/jaarKmMotiefs))),  // limietKmJaarPrijs verdelen volgens gewicht
    })),
    jaarKmMotiefs
  }
}

// B
const findFormulaForAutoAndAndAbo =  (toFindVolgnr, abo) => abo.formules.find(({ autos }) => autos.some(({volgnr}) => toFindVolgnr === volgnr))

const findFormulaAndAutoForMotiefAndAbo = (motief, abo) => {
  let toFindVolgnr = motief.auto.volgnr
  let otherAuto = false
  let f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr - 1
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr - 2
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr - 3
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr - 4
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr - 5
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr + 1
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr + 2
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr + 3
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr + 4
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  if (f == null) {
    otherAuto = true
    toFindVolgnr = motief.auto.volgnr + 5
    f = findFormulaForAutoAndAndAbo(toFindVolgnr, abo)
  }
  // console.log('otherAuto', otherAuto);
  
  return ({ formula: f, foundVolgnr: toFindVolgnr, otherAuto })
}

// C
const calcKmPrijsForMotiefAndFormula = (formula, motief) => {
  // kmprijzen: bereken per schijf (vb als 150km, dan eerst de schijf 0-100km aan de juiste prijs en dan de de schijf 100-150 aan de juiste prijs)
  let kmPrijs = 0
  // TODO kan dit ook niet eenmalig gebeuren? of memoizen?
  const kmprijzenS = formula.kmprijzen.sort(compareKmprijzen)
  // console.log('kmprijzenS', kmprijzenS);

  if (kmprijzenS.length > 0) {
    let i = 0
    let restkm = motief.afstand
    let vorigeTrapMaxKm = 0

    do {
      const {maxkm, prijsperkm, kmeenheid} = kmprijzenS[i]
      
      if (maxkm == null) {
        kmPrijs += restkm * prijsperkm / kmeenheid
        restkm = 0
      } else {
        const dezeTrapMaxKm = maxkm - vorigeTrapMaxKm
        if (restkm <= dezeTrapMaxKm) {
          kmPrijs += restkm * prijsperkm / kmeenheid
          restkm = 0
        } else {
          kmPrijs += dezeTrapMaxKm * prijsperkm / kmeenheid
          restkm = restkm - dezeTrapMaxKm
        }
        vorigeTrapMaxKm = dezeTrapMaxKm
      }
      i ++
      // console.log(kmPrijs, restkm);
    } while (restkm > 0)
  }
  // console.log('kmPrijs', kmPrijs);
  return kmPrijs
}

// D
const calcTijdPrijsForMotiefAndFormula = (formula, motief) => {
  let tijdPrijs = 0
  // tijdsduur: bereken alle die in aanmerking komen en neem de goedkoopste
  const pertijd = formula.tijdstarieven.map(({ moment, duureenheid, duur, prijs, weekends, stilstand, prijsperkm }) => {
    
    if (((motief.moment === 'dag' && (moment === 'nvt' || moment === 'dag')) ||
         (motief.moment === 'nacht' && (moment === 'nvt' || moment === 'nacht'))) &&
        ((motief.weekend && (weekends === 'nvt' || weekends === 'ja')) ||
         (!motief.weekend && (weekends === 'nvt' || weekends === 'nee'))) &&
        (stilstand == null || !stilstand)
      ) {
      // console.log('duur', duur, duureenheid, prijs);
      // console.log('motief.duureenheid', motief.duureenheid);
      // console.log('motief.duur', motief.duur);
      // duur motief = altijd een aantal uren of een aantal dagen
      // duur tijdstarief = uur of minuut
      // console.log("duureenheid", duureenheid, "duur", motief.duur / duur);
      //const result = Math.ceil((motief.duureenheid === "uur" ? motief.duur : motief.duur*24) / (duureenheid === "uur" ? duur : (duur / 60))) * prijs
      let result = Math.ceil(
        (motief.duureenheid === "minuten"
          ? (motief.duureenheid === "uur" ? 1 : motief.duur / 60) 
          : (motief.duureenheid === "uur" ? motief.duur : motief.duur * 24)) /   // uitdrukken in uur
        (duureenheid === "uur" ? duur : (duur / 60))                        // uitdrukken in uur
      ) * prijs
      // console.log('result', result);

      if (prijsperkm && prijsperkm !== 0) {
        result += motief.afstand * prijsperkm
      }
      return result
    } else {
      return 99999999
    }
  })
  // console.log('formula', formula.naam);
  // console.log('pertijd', pertijd);
  const pertijdSorted = pertijd.sort((a, b) => a - b)
  if (pertijdSorted.length > 0 && pertijdSorted[0] !== 99999999) {
    tijdPrijs += pertijdSorted[0]
  }

  return tijdPrijs
}

// D1
const calcTijdPrijsForMotiefAndFormulaForGreenMobility = (formula, motief) => {
  // console.log('motief', motief)
  let tijdPrijs = 0
  // tijdsduur: bereken alle die in aanmerking komen en neem de goedkoopste
  const perDuur = formula.tijdstarieven.map(({ moment, duureenheid, duur, prijs, weekends, stilstand, prijsperkm }) => {
    const duurInMinuten = duureenheid === "minuten" ? duur : duur * 60
    const returnValue =  {moment: moment, duureenheid:duureenheid,duur:duur,prijs:prijs, weekend:weekends, stilstand:stilstand,           prijsperkm:prijsperkm, duurInMinuten:duurInMinuten}
    return returnValue
  })
  // console.log(perDuur)
  const sortPerDuur = Object.values(perDuur).sort((a,b) => b.duurInMinuten - a.duurInMinuten)
  // console.log('sortPerDuur', sortPerDuur)
  const duurVanMotiefInMinuten = motief.duureenheid === "minuten" ? motief.duur : 
          (motief.duureenheid === "uur" ? motief.duur * 60 : motief.duur * 60 *24)
  let gevondenDuur
  for( const eenDuur of sortPerDuur ) {
    // console.log('duurVanMotiefInMinuten', duurVanMotiefInMinuten, 'eenDuur.duurInMinuten', eenDuur.duurInMinuten)
    if( duurVanMotiefInMinuten >= eenDuur.duurInMinuten) {
      gevondenDuur = eenDuur;
      break;
    }
  }
  // console.log('gevondenDuur', gevondenDuur)
  let result = Math.ceil(
    (motief.duureenheid === "minuten"
      ? (motief.duureenheid === "uur" ? 1 : motief.duur / 60) 
      : (motief.duureenheid === "uur" ? motief.duur : motief.duur * 24)) /   // uitdrukken in uur
    (gevondenDuur.duureenheid === "uur" ? gevondenDuur.duur : (gevondenDuur.duur / 60))                        // uitdrukken in uur
  ) * gevondenDuur.prijs
  // console.log('result', result);

  if (gevondenDuur.prijsperkm && gevondenDuur.prijsperkm !== 0) {
    result += motief.afstand * gevondenDuur.prijsperkm
  }
  return result

}

// E
const calcBrandstofForMotiefAndFormula = (formula, auto, motief, brandstofs) => {
  if (formula.brandstof === true) {
    return calcKmPrijsForMotiefAndBrandstof(brandstofs.prijsperliter, auto.verbruik, motief)
  } else {
    return 0
  }

}

// F
const calcStilstandprijs = (formula, motief) => {
  if (motief.duureenheid === 'uur' || motief.duureenheid === 'minuten') {
    const stilstandTarief = formula.tijdstarieven.find(({stilstand}) => stilstand != null && stilstand)
    const dagTarief = formula.tijdstarieven.find(({duureenheid, duur}) => duureenheid === "uur" && duur === 24)
    const minuutTarief = formula.tijdstarieven.find(({duureenheid, duur, stilstand}) => (stilstand == null || !stilstand)  && duureenheid === "minuut" && duur === 1)
    if (stilstandTarief != null) {
      if (dagTarief == null || ((minuutTarief.prijs * 60) * (motief.duureenheid === 'uur' ? motief.duur : motief.duur / 60)) < dagTarief.prijs) {  // om zeker te zijn dat we niet in een dagtarief vallen
        const kortingPerMinuut = minuutTarief.prijs - stilstandTarief.prijs
        const stilstandTijd = ((motief.duureenheid === 'uur' ? motief.duur : motief.duur / 60) - (motief.afstand / 30))*60      // we veronderstellen een gemiddelde snelheid van 30km/h   -   stilstandTijd in minuten
        if (stilstandTijd > 0) {
          return stilstandTijd * kortingPerMinuut
        }
      }
    }
  }
  return 0
}

// G
// const calcKmLimitsPriceForMotiefAndFormula = (formula, motief) => {
//   // neem de eerste formula, zoek beperkingen per dag of per uur
//   const beperkingenPerDag = formula.afstandsbeperkingen.filter((b) => b.duureenheid === "dag")
//   if (beperkingenPerDag.length > 0) {   // we gaan uit van 1 beperking per dag
//     // spreid evt. de afstand over de dagen
//     const teveelKmPerDag = (motief.duureenheid === "dag" ? Math.ceil(motief.afstand / motief.duur) : motief.afstand) - beperkingenPerDag[0].maxkm

//     if (teveelKmPerDag > 0) {
//       const extraPrijsPerDag = Math.ceil(teveelKmPerDag / beperkingenPerDag[0].kmeenheid) * beperkingenPerDag[0].prijsperkm
//       // console.log('extraPrijsPerDag', extraPrijsPerDag);
//       return extraPrijsPerDag * (motief.duureenheid === "dag" ? motief.duur : 1)
//     }
//   }

//   const beperkingenPerUur = formula.afstandsbeperkingen.filter((b) => b.duureenheid === "uur")
//   if (beperkingenPerUur.length > 0) {         // we gaan uit van meerdere uur-beperkingen per dag
//       const beperkingenPerUurSorted = beperkingenPerUur.sort((a,b) => a.maxduur-b.maxduur)    // sort by largest period first
//       // console.log('beperkingenPerUurSorted', beperkingenPerUurSorted);
       
//       const applicableLimit = (motief.duureenheid === "uur" ? beperkingenPerUurSorted.find((b) => motief.duur <= b.maxduur) : beperkingenPerUurSorted[0])
//       // console.log('applicableLimit', applicableLimit);
//       const teveelKmPerDag = (motief.duureenheid === "dag" ? Math.ceil(motief.afstand / motief.duur) : motief.afstand) - applicableLimit.maxkm
//       if (teveelKmPerDag > 0) {
//         const extraPrijsPerDag = Math.ceil(teveelKmPerDag / beperkingenPerUurSorted[0].kmeenheid) * beperkingenPerUurSorted[0].prijsperkm   // we gaan ervan uit dat als limieten per dag, de grootste limiet er 1 is voor een dag
//         // console.log('extraPrijsPerDag', extraPrijsPerDag);
//         return extraPrijsPerDag * (motief.duureenheid === "dag" ? motief.duur : 1)
//       }      
//   }
//   return 0
// }

const calcKmLimitsPriceForMotiefAndFormula = (formula, motief) => {
  const beperkingenPerUur = formula.afstandsbeperkingen.filter((b) => b.duureenheid === "uur")
  if (beperkingenPerUur.length > 0) {
    const beperkingenPerUurSorted = beperkingenPerUur.sort((a,b) => a.maxduur-b.maxduur)    // sort by smallest period first
    // console.log('beperkingenPerUurSorted', beperkingenPerUurSorted);
    let lengteMotiefInUren = motief.duureenheid === "uur" ? motief.duur : motief.duur * 24
    let maxGratisAfstand = 0

    do {
      let applicableLimit = beperkingenPerUurSorted.find((b) => lengteMotiefInUren <= b.maxduur)
      if (applicableLimit == null) {
        applicableLimit = beperkingenPerUurSorted[beperkingenPerUurSorted.length - 1]
      }
      maxGratisAfstand += applicableLimit.maxkm
      lengteMotiefInUren -= applicableLimit.maxduur
    } while (lengteMotiefInUren > 0)
    // console.log('maxGratisAfstand', maxGratisAfstand)
    const teveelKm = motief.afstand - maxGratisAfstand
    if (teveelKm > 0) {
      return (teveelKm * beperkingenPerUurSorted[0].prijsperkm)
    }
  }
  return 0
}

// P
const multiplyPriceWithFrequency = (price, motief) => {
  // frequentie = alles vermenigvuldigen met frequentie  
  return (price * motief.frequentie * (motief.frequentietype === "week" ? 52 : (motief.frequentietype === "maand" ? 12 : 1)))
}

// Q
const calcAboPriceForAYear = (abo, prepaid) => {
  if (prepaid != null) {
    return prepaid.bedrag  // smear prepaid over 1 year
  } else {
    if (abo.frequentie != null) {
      if (abo.frequentie === "jaarlijks") {
        return abo.prijs
      } else if (abo.frequentie === "maandelijks") {
        return abo.prijs * 12
      } else if (abo.frequentie === "driemaandelijks") {
        return abo.prijs * 4
      } else {
        return abo.prijs
      }
    }
  }
  return 0
}

// R
const calcTimeLimitsPriceForMotiefsAndAbo = (motiefs, abo) => {     // dit betreft de tijdsbeperkingen in dagen / maand
  if (abo.formules[0].tijdsbeperkingen.length > 0) {
    const tijdsbeperkingen = abo.formules[0].tijdsbeperkingen.find((tb) => tb.periodeeenheid === "maand")

    if (tijdsbeperkingen != null && tijdsbeperkingen.maxeenheid === 'dag') {
      if (tijdsbeperkingen.minaantal != null) {
        const daysPerMonth = getDaysPerMonth(motiefs)
        // console.log("daysPerMonth", daysPerMonth);
        return daysPerMonth.reduce((totTijdPrijs, days) => {
          // console.log("minaantal", abo.naam);          
          const tekort = tijdsbeperkingen.minaantal - days
          if (tekort > 0) {
            totTijdPrijs += tekort * abo.formules[0].tijdstarieven[0].prijs       // specifiek battmobiel, ttz dagprijs + we nemen hier de eerste formule - is niet per se de goedkoopste (OK? TODO)
          }
          // console.log('totTijdPrijs', totTijdPrijs)
          return totTijdPrijs
        }, 0)
      }
      if (tijdsbeperkingen.maxaantal != null) {
        const hoursPerDayPerMonth = getHoursPerMonth(motiefs)
        
        // console.log("hoursPerDayPerMonth", hoursPerDayPerMonth);
        return hoursPerDayPerMonth.reduce((totTijdPrijs, days) => {
          let teveel = 0
          // de dagen die over de max gaan, daarvan de uren nemen
          for (let d = tijdsbeperkingen.maxaantal; d < 30; d++) {
            teveel += days[d]
          }
          if (teveel > 0) {
            totTijdPrijs += teveel * tijdsbeperkingen.extraprijsperuur
          }
          // console.log('totTijdPrijs', totTijdPrijs)
          return totTijdPrijs
        }, 0)
      }
    } else {
      return 0
    }
  } else {
    return 0
  }
}

// S
const calcKmLimitsPriceForMotiefsAndAbo = (motiefs, abo) => {
  // neem de eerste formula, alleen als kmprijzen per maand bekeken worden (anders wordt die limiet per motief bekeken)
  const beperkingPerMaand = abo.formules[0].afstandsbeperkingen.find((b) => b.duureenheid === "maand")
  if (beperkingPerMaand != null) {
    const kmsPerMonth = getKmsPerMonth(motiefs)
    return kmsPerMonth.reduce((totKmPrijs, km) => {
      const teveel = km - beperkingPerMaand.maxkm
      // console.log('teveel', teveel);
      if (teveel > 0) {
        totKmPrijs += teveel * beperkingPerMaand.prijsperkm
      }
      return totKmPrijs
    }, 0)
  } else {
    return 0
  }
}

// T1 - hier spreiden we de motieven over de dagen (alsof je nooit motieven combineert op 1 dag)
const getDaysPerMonth = (motiefs) => {      //   TODO, staat los van abo's, is per motief ==> memoize?
  let daysPerMonth = [0,0,0,0,0,0,0,0,0,0,0,0]
  let verdeelJaarlijkse = 0
  motiefs.forEach((motief,idx) => {
    const toAddDays = (motief.duureenheid === "dag" ? motief.duur : 1)
    if (motief.frequentietype === "jaar") {
      for (let i = 0; i < motief.frequentie; i++) {
        daysPerMonth[verdeelJaarlijkse] += toAddDays
        if (verdeelJaarlijkse < 11) {
          verdeelJaarlijkse += 1
        } else {
          verdeelJaarlijkse = 0
        }
      }
    } else if (motief.frequentietype === "maand") {
      daysPerMonth = daysPerMonth.map((day) => day + toAddDays * motief.frequentie)
    } else if (motief.frequentietype === "week") {
      daysPerMonth = daysPerMonth.map((day) => day + toAddDays * Math.round(motief.frequentie*4.3))  // 52/12 = 4.3 weken in een maand
    }
  })
  return daysPerMonth
}

// T2 - hier proberen we motieven op 1 dag te combineren waar mogelijk
const getHoursPerMonth = (motiefs) => {      //   TODO, staat los van abo's, is per motief ==> memoize?

  let hoursPerDayPerMonth = Array(12).fill(Array(30).fill(0))
  // console.log('hoursPerDayPerMonth', hoursPerDayPerMonth);
  let verdeelJaarlijkse = 0
  motiefs
    .sort((a,b) => {
      if (a.duureenheid === 'dag' && b.duureenheid === 'dag') {
        return 0
      } else if (a.duureenheid === 'dag') {
        return -1
      } else {
        return 1
      }
    })
    .forEach((motief,idx) => {
    // const toAddHours = (motief.duureenheid === "dag" ? motief.duur * 24 : motief.duur)
    if (motief.frequentietype === "jaar") {
      for (let i = 0; i < motief.frequentie; i++) {
        // hoursPerMonth[verdeelJaarlijkse] += toAddHours
        hoursPerDayPerMonth[verdeelJaarlijkse] = addMotiefDuurToMonth({ ...motief, frequentie: 1}, hoursPerDayPerMonth[verdeelJaarlijkse])
        if (verdeelJaarlijkse < 11) {
          verdeelJaarlijkse += 1
        } else {
          verdeelJaarlijkse = 0
        }
      }
    } else if (motief.frequentietype === "maand") {
      // hoursPerMonth = hoursPerMonth.map((day) => day + toAddHours * motief.frequentie)
      hoursPerDayPerMonth = hoursPerDayPerMonth.map((m) => addMotiefDuurToMonth(motief, m))
    } else if (motief.frequentietype === "week") {
      // hoursPerMonth = hoursPerMonth.map((day) => day + toAddHours * Math.round(motief.frequentie*4.3))  // 52/12 = 4.3 weken in een maand
      hoursPerDayPerMonth = hoursPerDayPerMonth.map((m) => addMotiefDuurToMonth({ ...motief, frequentie: motief.frequentie*4}, m))
    }
  })
  return hoursPerDayPerMonth
}

// T2- hulpfunctie
const addMotiefDuurToMonth = (motief, m) => {
  let newM = [...m]
  if (motief.duureenheid === "dag") {
    for (let f = 0; f < motief.frequentie; f++) {
      for (let d = 0; d < motief.duur; d++) {
        let firstEmptyDayIdx = newM.findIndex((d) => d === 0)
        if (firstEmptyDayIdx !== -1) {
          newM[firstEmptyDayIdx] = 24
        }
      }
    }
  }
  if (motief.duureenheid === "uur") {
    let nextD = 0 // om te verhinderen dat je bij dezelfde dag zou bijtellen
    for (let f = 0; f < motief.frequentie; f++) {
      let firstPossibleDayIdx = newM.findIndex((d,idx) => idx >= nextD && (d + motief.duur <= 24))
      if (firstPossibleDayIdx !== -1) {
        newM[firstPossibleDayIdx] += motief.duur
        nextD = firstPossibleDayIdx+1
      } else {
        nextD = newM.length
      }
    }
  }
  return newM
}

// U
const getKmsPerMonth = (motiefs) => {     //   TODO, staat los van abo's, is per motief ==> memoize?
  let kmsPerMonth = [0,0,0,0,0,0,0,0,0,0,0,0]
  let verdeelJaarlijkse = 0
  motiefs.forEach((motief,idx) => {
    if (motief.frequentietype === "jaar") {
      // console.log("jaar");
      
      for (let i = 0; i < motief.frequentie; i++) {
        kmsPerMonth[verdeelJaarlijkse] += motief.afstand
        if (verdeelJaarlijkse < 11) {
          verdeelJaarlijkse += 1
        } else {
          verdeelJaarlijkse = 0
        }
      }
    } else if (motief.frequentietype === "maand") {
      kmsPerMonth = kmsPerMonth.map((km) => km + motief.afstand*motief.frequentie)
    } else if (motief.frequentietype === "week") {
      kmsPerMonth = kmsPerMonth.map((km) => km + motief.afstand*motief.frequentie*4)
    }
  })
  return kmsPerMonth
}

// TODO dit maar 1 maal uitvoeren bij opladen formules
const compareKmprijzen = (a,b) => {
  if (a.maxkm==null) {
    return 1
  } else if (b.maxkm==null) {
    return -1
  } else if (a.maxkm>b.maxkm) {
    return 1
  } else {
    return -1
  }
}

// -----------------------------------------------------------------------

export const calcMotiefsForCar = (motiefs, car, afschrijving, brandstofs) => {
  // console.log('calcMotiefsForCar', car);
  let jaarPrijsMotiefs = 0
  let jaarKmMotiefs = 0
  const motiefsWithJaarPrijs = motiefs.map((motief) => {
    
    const kmPrijs = calcKmPrijsForMotiefAndBrandstof(car.electric ? brandstofs.prijsperkWh : brandstofs.prijsperliter, car.electric ? car.auto.verbruikelektrisch : car.auto.verbruik, motief)

    const jaarPrijs = multiplyPriceWithFrequency(kmPrijs, motief)
    jaarPrijsMotiefs += jaarPrijs

    const jaarKm = motief.afstand  * motief.frequentie * (motief.frequentietype === "week" ? 52 : (motief.frequentietype === "maand" ? 12 : 1))
    jaarKmMotiefs += jaarKm
    
    return ({ ...motief, jaarPrijs, jaarKm })
  })
  const vasteJaarPrijs = calcFixPriceForAYear(car, afschrijving)
  // console.log('vasteJaarPrijs', vasteJaarPrijs)


  return {
    // naam: `Eigen wagen (${car.auto.naam}${car.electric ? ' elektrisch' : ''})`,
    carId: car.id,
    jaarPrijsMotiefs: Math.round(jaarPrijsMotiefs + vasteJaarPrijs),
    jaarPrijsMotiefsZonderAbo: Math.round(jaarPrijsMotiefs + vasteJaarPrijs), // abo bestaat niet bij eigenwagen, maar deze prijs wordt gebruikt in de resultatentabel
    motiefs: motiefsWithJaarPrijs.map((motief) => ({
      ...motief,
      jaarPrijs: motief.jaarPrijs + (vasteJaarPrijs === 0 ? 0 : Math.round(vasteJaarPrijs*(motief.jaarKm/jaarKmMotiefs)))        // vasteJaarPrijs verdelen volgens gewicht
    })).map((motief) => ({
      ...motief,
      jaarPrijsZonderAbo: motief.jaarPrijs // abo bestaat niet bij eigenwagen, maar deze prijs wordt gebruikt in de resultatentabel
    })),
    carelectric: car.electric,
    cartype: car.auto.naam
  }
}

export const calcFixPriceForAYear = (car, afschrijving) => {
  const { verzekering, verzekeringelektrisch, pechhulp, garage, onderhoud, onderhoudelektrisch, belasting, belastingelektrisch, keuring, herstellingen, herstellingenelektrisch, carwash, electric } = car
  return (
    calcRestWaarde(car, afschrijving)
    + (electric ? verzekeringelektrisch : verzekering)
    + pechhulp
    + garage
    + (electric ? onderhoudelektrisch : onderhoud)
    + (electric ? belastingelektrisch : belasting)
    + keuring
    + (electric ? herstellingenelektrisch : herstellingen)
    + carwash
  )
}

const calcKmPrijsForMotiefAndBrandstof = (prijsperliter, verbruik, motief) => {
  return (motief.afstand / 100) * verbruik * prijsperliter
}

const calcRestWaarde = (car, afschrijving) => {
  const { nieuwprijs, nieuwprijselektrisch, leeftijd, kmstand, auto, electric } = car
  let restwaarde = electric ? nieuwprijselektrisch : nieuwprijs

  // console.log('auto', auto.volgnr)
  // 1. volgens leeftijd
  // komt uit STRAPI
  // const waardeverminderingPetrolPct = [1, 0.75, 0.82, 0.85, 0.88, 0.90]
  // const waardeverminderingElectricPct = [1, 0.85, 0.877, 0.877, 0.877, 0.877]
  let waardeverminderingPct = electric ? afschrijving.afschrijvingelektrisch.map(({ restwaarde }) => restwaarde) : afschrijving.afschrijvingbenzine.map(({ restwaarde }) => restwaarde)

  let jaar = 0
  do {
    restwaarde = restwaarde * (jaar<5 ? waardeverminderingPct[jaar] : waardeverminderingPct[5])
    jaar ++
  } while (jaar <= leeftijd)
  // console.log('restwaarde dit jaar', restwaarde)
  const restwaardeVolgenJaar = restwaarde * (jaar<5 ? waardeverminderingPct[jaar] : waardeverminderingPct[5])
  // console.log('restwaarde volgend jaar', restwaardeVolgenJaar)
  let waardeverminderingDitJaar = restwaarde - restwaardeVolgenJaar

  // console.log('waardeverminderingDitJaar 1', waardeverminderingDitJaar)

  // 2. volgens kmstand
  // komt uit STRAPI
  // const kantelPuntenPetrol = [10000, 11500, 13000, 14500, 16000, 13500] // per auto-categorie
  // const kantelPuntenElectric = [10000, 10000, 10000, 10000, 10000, 10000] // per auto-categorie
  let kantelPunten = electric ? afschrijving.kantelpuntelektrisch.map(({ km }) => km) : afschrijving.kantelpuntbenzine.map(({ km }) => km)

  const kantelPunt = kantelPunten[auto.volgnr - 1]
  const kmPerJaar = leeftijd > 0 ? kmstand/leeftijd : 12000
  // console.log('kmPerJaar', kmPerJaar)
  waardeverminderingDitJaar = waardeverminderingDitJaar + (kmPerJaar < kantelPunt ? -((kantelPunt-kmPerJaar) / 1000) * 15 : ((kmPerJaar-kantelPunt) / 1000) * 30)

  // console.log('waardeverminderingDitJaar 2', waardeverminderingDitJaar)

  return waardeverminderingDitJaar
}