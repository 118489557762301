import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
// import logoA from '../../images/logo_autodelen_black.png'

import logoA from '../../images/nieuw_logo_autodelen.png'
import logoB from '../../images/logo_bxl_grey.png'
import logoC from '../../images/logo_ta.png'
import logoD from '../../images/logo_sharedimobihyb.png'
import logoE from '../../images/logo_europese_vlag.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    // height: 60,
    marginTop: 100,
    backgroundColor: theme.palette.primary.light
  },
  // footer: {
  //   height: '100%',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  //   '& a': {
  //     color: theme.palette.text.primary,
  //     cursor: 'pointer',
  //     textDecoration: 'underline'
  //   }
  // }
  upperpart: {
    // height: 120,
    paddingBottom: 30,
    paddingTop: 30
  },
  lowerpart: {
    // height: 60,
    // borderTop: `1px solid ${theme.palette.primary.main}`,
    borderTop: `1px solid lightgrey`,
    paddingBottom: 20,
    paddingTop: 20,
    textAlign: 'center'
  },
  lowerparts: {
    '& span': {
      marginRight: 20
    },
    '& a': {
      color: theme.palette.text.primary
    }
  },
  logo: {
    // maxHeight: '100%',
    // maxWidth: '100%',
    height: 60,
    objectFit: 'contain'
  },
  logoGrey: {
    opacity: 0.5
  },
  link: {
    color: theme.palette.text.primary
  },
  logos: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '& a': {
      padding: 20
    }
  },
  info: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: 20
  }
}))

const Footer = ({ versiedatum, labels, language }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>

      <Container maxWidth="lg" className={classes.upperpart}>
        <Grid container>
          <Grid item sm={12} md={8} className={classes.logos}>
            <a href='https://www.autodelen.net' target="_blank" rel="noopener noreferrer">
              <img src={logoA} className={classes.logo + ' ' + classes.logoGrey} alt='autodelen'/>
            </a>
            <a href='https://mobilite-mobiliteit.brussels' target="_blank" rel="noopener noreferrer">
              <img src={logoB} className={classes.logo} alt='mobilite-mobiliteit.brussels' />
            </a>
            <a href='https://www.test-aankoop.be' target="_blank" rel="noopener noreferrer">
              <img src={logoC} className={classes.logo} alt='test-aankoop' />
            </a>
            <a href='https://northsearegion.eu/' target="_blank" rel="noopener noreferrer">
              <img src={logoD} className={classes.logo} alt='share-north' />
            </a>
            <a href='https://european-union.europa.eu' target="_blank" rel="noopener noreferrer">
              <img src={logoE} className={classes.logo} alt='europese-vlag' />
            </a>
          </Grid>
          <Grid item sm={12} md={4} className={classes.info}>
            <span>
              <div><Link to="/formules" target="_blank" className={classes.link}>{labels.label_prijzen[language]}</Link>
                {` ${labels.label_geldig_op[language]} ${versiedatum.toLocaleString(`${language}-BE`, { year: 'numeric', month: 'long', day: 'numeric' })}`}
              </div>
              <div><Link to="/werkwijze" target="_blank" className={classes.link}>{labels.label_werkwijze[language]}</Link></div>
            </span>

          </Grid>

        </Grid>
      </Container>



      <Container maxWidth="lg" className={classes.lowerpart}>
        <Typography variant="body2" className={classes.lowerparts}>
          <span>© <a href='https://www.autodelen.net' target="_blank" rel="noopener noreferrer">Autodelen.net</a></span>
          <span><Link to="/privacy" target="_blank" className={classes.link}>{labels.label_privacybeleid[language]}</Link></span>
        </Typography>
      </Container>
    </div>
  )
}

export default Footer
